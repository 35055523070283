<template>
  <div class="content">
    <div class="contentTop">
      <nav-bar/>
      <div class="gifImg">
        {{ $t('lsmc.title') }}
      </div>
    </div>
    <div class="contentMain">
      <div class="mainLeft">
        <!--运营-->
        <div class="boxAll">
          <div class="gotClass">
            <div>
              <span>{{ $t('lsmc.operate') }}</span>
            </div>
          </div>
          <div class="list" v-for="(item ,index) in info" :key="index">
            <img
                :class="{img0: index===0, img1: index===1, img2: index===2, img3: index===3, img4: index===4, img5: index===5, img6: index===6, img7: index===7}"
                :src="require('../assets/img_'+index + '@2x.png')" alt/>
            <div class="contents">
              <span>{{ item.name }}</span>
              <count-to
                  :class="{active0: index===0, active1: index===1, active2: index===2, active3: index===3, active4: index===4, active5: index===5, active6: index===6, active7: index===7}"
                  :startVal="0" :endVal="item.num" :duration="3000"></count-to>
            </div>
          </div>
          <div class="boxFoot"></div>
        </div>
      </div>
      <div class="mainRight">
        <div class="rightTop">
          <div class="rightLeft">
            <div class="mapTitle1">
              <div class="mapTitle">
                <span>{{ $t('lsmc.slabTotal') }}(m²)</span>
                <countTo :startVal="slabCutAreaStart" :endVal="slabCutAreaEnd" :duration="durationTime"
                         :decimals="2"></countTo>
              </div>
              <div class="mapTitle">
                <span>{{ $t('lsmc.cellTotal') }}(m²)</span>
                <countTo :startVal="cellCutAreaStart" :endVal="cellCutAreaEnd" :duration="durationTime"
                         :decimals="2"></countTo>
              </div>
            </div>
            <!--地图-->
            <div class="map">
              <div class="map4" id="map_1"></div>
            </div>
          </div>
          <div class="rightRight">
            <!--设备切割占比-->
            <div class="item device">
              <div class="itemTitle">
                <div>
                  <span>{{ $t('lsmc.cut') }}</span>
                </div>
              </div>
              <div id="deviceChart" class="itemBg"></div>
              <div class="boxFoot"></div>
            </div>
            <!--企业切割排行-->
            <div class="item company">
              <div class="itemTitle">
                <div>
                  <span>{{ $t('lsmc.deviceTotal') }}</span>
                </div>
              </div>
              <div class="itemCon itemBg">
                <div v-for="(item, index) in cutList" :key="index" class="itemContent">
                  <div class="radio"
                       :class="{radio1: index===1, radio2: index===2, radio3: index===3, radio4: index===4, radio5: index===5, radio6: index===6, radio7: index===7 }"></div>
                  <span>{{ item.deviceModelCode }}</span>
                  <span>{{ item.deviceCutArea }}</span>
                </div>
              </div>
              <div class="boxFoot"></div>
            </div>
          </div>
        </div>
        <div class="rightBottom">
          <!--本月切割统计-->
          <div class="item">
            <div class="btnClass">
              <div class="list" :class="{active: btnActive === 0}"
                   @click="change(0)">
                {{ $t('lsmc.slab') }}
              </div>
              <div class="list" :class="{active: btnActive === 1}"
                   @click="change(1)">
                {{ $t('lsmc.cell') }}
              </div>
            </div>
            <div class="itemTitle">
              <div>
                <span>{{ $t('lsmc.cutTotal') }}</span>
              </div>
            </div>
            <div id="thisMonth" class="itemBg"></div>
            <div class="boxFoot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//导入数字滚动
import countTo from "vue-count-to"
import NavBar from './navBar.vue';
import * as echarts from 'echarts';
import chinaMap from '../assets/map/china.json';

export default {
  name: "IndexContent",
  props: {
    info: {
      type: Array
    },
    cutData: {
      type: Object
    },
    deviceList: {
      type: Array
    },
    orgY: {
      type: Array
    },
    orgList: {
      type: Array
    },
    rateX: {
      type: Array
    },
    rateList: {
      type: Array
    },
    cutList: {
      type: Array
    },
    mapList: {
      type: Array
    },
    slabCutAreaEnd: {
      type: Number
    },
    cellCutAreaEnd: {
      type: Number
    }
  },
  mounted() {
    this.drawMap()
    this.drawDevice()
    this.drawThis()
  },
  data() {
    return {
      multiple: 2,
      slabCutAreaStart: 0,
      cellCutAreaStart: 0,
      durationTime: 5000,
      btnActive: 0,
      btn: [this.$t('lsmc.slab'), this.$t('lsmc.cell')],
      pie1: undefined,
      trendBar1: undefined,
      myChart: undefined
    }
  },
  components: {
    countTo,
    NavBar
  },
  watch: {
    slabCutAreaEnd(newValue, oldValue) {
      this.slabCutAreaStart = oldValue
      this.durationTime = 3000
    },
    cellCutAreaEnd(newValue, oldValue) {
      this.cellCutAreaStart = oldValue
      this.durationTime = 3000
    },
    mapList: {
      handler(newValue, oldValue) {
        this.drawMap()
        for (let i = 0; i < newValue.length; i++) {
          if (oldValue[i] != newValue[i]) {
            // console.log(newValue)
          }
        }
      }
    },
    deviceList: {
      handler(newValue, oldValue) {
        for (let i = 0; i < newValue.length; i++) {
          if (oldValue[i] != newValue[i]) {
            this.drawDevice()
          }
        }
      }
    },
    rateX: {
      handler(newValue, oldValue) {
        for (let i = 0; i < newValue.length; i++) {
          if (oldValue[i] != newValue[i]) {
            this.drawThis()
          }
        }
      }
    },
    rateList: {
      handler(newValue, oldValue) {
        this.drawThis()
        for (let i = 0; i < newValue.length; i++) {
          if (oldValue[i] != newValue[i]) {
            // this.drawThis()
          }
        }
      }
    },
    // cutData: {
    //   handler(newValue, oldValue) {
    //     this.slabCutAreaStart = oldValue.slabCutArea
    //     this.cellCutAreaStart = oldValue.cellCutArea
    //     this.durationTime = 3000
    //   }
    // },
  },
  destroyed() {
    this.pie1.dispose()
    this.trendBar1.dispose()
    this.myChart.dispose()
  },
  methods: {
    //
    routerInLsmc() {
      this.$router.push('/lsmc')
    },
    routerInLsdc() {
      this.$router.push('/lsdc')
    },
    routerIndbsc() {
      this.$router.push('/dbsc')
    },
    change(index) {
      this.btnActive = index
    },
    drawDevice() {
      this.pie1 = echarts.init(document.getElementById('deviceChart'), 'macarons');
      const pieRich = {
        big: {
          fontSize: 24,
          lineHeight: 40,
          fontFamily: 'Rubik'
        },
        small: {
          fontSize: 16
        }
      }
      let pieOption1 = {
        series: [
          {
            name: this.$t('lsmc.cutRate'),
            type: 'pie',
            radius: [0, 70],
            color: ['#006bd1', '#00FAFA', '#AF89D6', '#f36f8a', '#26F3E8', '#97E001', '#B56046'],
            label: {
              normal: {
                show: true,
                position: 'outer',
                alignTo: 'labelLine',
                bleedMargin: 10,
                color: '#999',
                lineHeight: 18,
                fontSize: 16,
                formatter: function (params) {
                  return params.value + 'm²\n' + params.name + '\n' + params.percent + '%';
                },
              },
              emphasis: {
                show: true,
                color: '#fff',
                formatter: function (params) {
                  return params.value + 'm²\n' + params.name + '\n' + params.percent + '%';
                },
                rich: pieRich
              }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 20,
            },
            data: this.deviceList
          }
        ]
      };
      this.pie1.setOption(pieOption1);
      const _this = this
      window.addEventListener("resize", function () {
        _this.pie1.resize();
      });
      setInterval(function () {
      }, 5000);
    },
    drawThis() {
      // 柱形折线图
      this.trendBar1 = echarts.init(document.getElementById('thisMonth'));
      let optionBar = {
        // animationDuration: 3000,
        // animationDurationUpdate: 2000,
        // animationThreshold: 4000,
        // progressiveThreshold: 3000,
        // progressive: 400,
        // hoverLayerThreshold: 3000,
        // useUTC: false,
        tooltip: {
          trigger: 'axis',
          textStyle: {
            align: 'left'
          },
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            return params[0].axisValue + '<br/>' + params[0].marker + '本日切割量：' + params[0].data.value + '㎡' + '<br/>' + params[0].marker + '增长率：' + params[0].data.rate;
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '4%',
          top: '12%',
          containLabel: true
        },
        xAxis: {
          name: this.$t('lsmc.date'),
          type: 'category',
          nameTextStyle: {
            fontSize: 16,
            color: 'rgba(255,255,255,.7)',
            padding: [30, 0, 0, -10]    // 四个数字分别为上右下左与原位置距离
          },
          data: this.rateX,
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.6)",
              fontSize: 16,
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.3)'
            }
          },
        },
        yAxis: {
          // name: '增\n长\n率\n%',
          // name: '增\n长\n量',
          name: this.$t('lsmc.word1') + '\n' + this.$t('lsmc.word2') + '\n' + this.$t('lsmc.word3'),
          type: 'value',
          splitNumber: 4,
          axisTick: {show: false},
          nameTextStyle: {
            fontSize: 16,
            lineHeight: 18,
            color: 'rgba(255,255,255,.7)',
            padding: [0, 180, -50, 0]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.2)'
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.6)",
              fontSize: 16,
            }
          },
          axisLine: {show: false},
        },
        series: [
          {
            name: this.$t('lsmc.word'),
            type: "line",
            smooth: true,
            symbol: 'circle', //折线点设置为实心点
            symbolSize: 8, //折线点的大小,
            // top:20,
            data: this.rateList,
            lineStyle: {
              normal: {
                width: 2
              },
            },
            itemStyle: {
              normal: {
                color: "#53C0E7"
              }
            }
          },
          {
            name: this.$t('lsmc.word'),
            type: "bar",
            // smooth: true,
            // symbol: 'circle', //折线点设置为实心点
            // symbolSize: 8, //折线点的大小,
            // top:20,
            data: this.rateList,
            lineStyle: {
              normal: {
                width: 2
              },
            },
            itemStyle: {
              normal: {
                color: "#006bd1"
              }
            }
          }
        ]
      };
      // trendBar1.clear()
      this.trendBar1.setOption(optionBar);
      const _this = this
      window.addEventListener("resize", function () {
        _this.trendBar1.resize();
      });
    },
    drawMap() {
      echarts.registerMap('china', {geoJSON: chinaMap})
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById('map_1'));
      let option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(50,50,50,0.7)',
          textStyle: {
            fontSize: 20,
            color: 'rgb(255,255,255)'
          },
          formatter: function (params) {
            if (params.data) {
              return params.name + '<br/>' + params.marker + '组织数：' + params.data.orgNum + '个' + '<br/>' + params.marker + '设备数：' + params.data.deviceNum + '个';
            }
          },
        },
        geo: {
          map: 'china',
          zoom: 1.3,
          aspectScale: 0.85,
          top: '14.8%',
          label: {
            emphasis: {
              show: true
            }
          },
          roam: false,//禁止其放大缩小
          itemStyle: {
            normal: {
              areaColor: '#4c60ff',
              borderColor: '#002097'
            },
            emphasis: {
              areaColor: '#293fff'
            }
          },
          select: {
            itemStyle: {
              normal: {
                areaColor: '#4c60ff',
                borderColor: '#002097'
              },
              emphasis: {
                areaColor: '#293fff'
              }
            },
          }
        },
        series: [
          {
            name: '中国',
            type: 'map',
            map: 'china',
            zoom: 1.3,
            aspectScale: 0.85,
            top: '14.8%',
            data: this.mapList,
            label: {
              normal: {
                position: 'right',
                show: false
              },
              emphasis: {
                show: true,
                textStyle: {
                  color: '#ffffff'
                }
              }
            },
            itemStyle: {
              normal: {
                areaColor: '#4c60ff',
                borderColor: '#002097'
              },
              emphasis: {
                areaColor: '#293fff'
              }
            },
            select: {
              itemStyle: {
                normal: {
                  areaColor: '#4c60ff',
                  borderColor: '#002097'
                },
                emphasis: {
                  areaColor: '#293fff'
                }
              },
            }
          }
        ]
      };
      this.myChart.setOption(option);
      let hourIndex = 0;
      let fhourTime = null;
      const _this = this;
      fhourTime = setInterval(function () {
        _this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,

        });
        _this.myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: hourIndex
        });
        _this.myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: hourIndex
        });
        hourIndex++;
        if (hourIndex >= _this.mapList.length) {
          hourIndex = 0;
        }
      }, 5000);
      //鼠标移入停止轮播
      _this.myChart.on("mousemove", function (e) {
        clearInterval(fhourTime)
        _this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        _this.myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex
        });
        _this.myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: e.dataIndex
        });
      })
      //鼠标移出恢复轮播
      _this.myChart.on("mouseout", function () {
        fhourTime = setInterval(function () {
          _this.myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,

          });
          _this.myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: hourIndex
          });
          _this.myChart.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: hourIndex
          });
          hourIndex++;
          if (hourIndex >= _this.mapList.length) {
            hourIndex = 0;
          }
        }, 5000);
      })
      window.addEventListener("resize", function () {
        _this.myChart.resize();
      });
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;

  .contentTop {
    height: 1.5rem;
    width: 100%;
    font-size: .32rem;
    line-height: .32rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    .gifImg {
      font-size: 0.42rem;
      line-height: 0.42rem;
      width: 100%;
      min-height: .5rem;
      text-align: center;
    }
  }

  .contentMain {
    color: #ffffff;
    display: flex;
    height: 82%;
    margin-top: 0.5rem;

    .mainLeft {
      width: 24%;
      padding: 0 0 0 .2rem;
      display: flex;

      .boxAll {
        padding-left: 1%;
        width: 100%;
        height: 10rem;
        padding-top: .2rem;
        position: relative;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .gotClass {
          position: absolute;
          top: -0.4rem;
          left: 0;
          height: .8rem;
          font-size: .18rem;
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          //border-bottom: 1px solid #1e5b6b;
          div {
            width: 2rem;
            height: 0.5rem;
            background: url("../assets/box.png") no-repeat;
            background-size: 100% 100%;
            line-height: 0.5rem;
          }
        }

        .list {
          width: 50%;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;

          .img0 {
            margin-right: .26rem;
            width: .3rem;
            height: .252rem;
          }

          .img1 {
            margin-right: .35rem;
            width: .312rem;
            height: .312rem;
          }

          .img2 {
            margin-right: .25rem;
            width: .276rem;
            height: .312rem;
          }

          .img3 {
            margin-right: .32rem;
            width: .276rem;
            height: .252rem;
          }

          .img4 {
            margin-right: .22rem;
            width: .336rem;
            height: .336rem;
          }

          .img5 {
            margin-right: .18rem;
            width: .312rem;
            height: .24rem;
          }

          .img6 {
            margin-right: .25rem;
            width: .288rem;
            height: .276rem;
          }

          .img7 {
            margin-right: .33rem;
            width: .252rem;
            height: .276rem;
          }

          .contents {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: .26rem;

            .active0 {
              color: rgba(3, 167, 240, 1);
              font-size: .28rem;
            }

            .active1 {
              color: rgba(255, 198, 40, 1);
              font-size: .28rem;
            }

            .active2 {
              color: rgba(3, 167, 240, 1);
              font-size: .28rem;
            }

            .active3 {
              color: rgba(255, 198, 40, 1);
              font-size: .28rem;
            }

            .active4 {
              color: rgba(3, 167, 240, 1);
              font-size: .28rem;
            }

            .active5 {
              color: rgba(255, 198, 40, 1);
              font-size: .28rem;
            }

            .active6 {
              color: rgba(3, 167, 240, 1);
              font-size: .28rem;
            }

            .active7 {
              color: rgba(255, 198, 40, 1);
              font-size: .28rem;
            }

            span:first-child {
              color: #ffffff;
              font-size: .18rem;
              margin-bottom: .1rem;
            }
          }
        }

        .boxFoot {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }

      .boxAll:before, .boxFoot:before {
        border-left: 2px solid #02a6b5;
        left: 0;
      }

      .boxAll:after, .boxFoot:after {
        border-right: 2px solid #02a6b5;
        right: 0;
      }

      .boxAll:before, .boxAll:after {
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        top: 0;
        border-top: 2px solid #02a6b5;
        border-radius: 0.03rem;
      }

      .boxFoot:before, .boxFoot:after {
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        bottom: 0;
        border-bottom: 2px solid #02a6b5;
        border-radius: 0.03rem;
      }
    }

    .mainRight {
      padding: 0 .2rem 0 0;
      width: 75%;
      display: flex;
      flex-direction: column;

      .rightLeft {
        float: left;
        width: 65%;
        position: relative;

        .mapTitle1 {
          position: absolute;
          padding: .1rem 0.1rem;
          bottom: 0.22rem;

          .mapTitle {
            margin-left: .3rem;
            margin-bottom: 0.2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: .36rem;
            color: #ffffff;

            span:first-child {
              margin-bottom: .1rem;
              font-size: .30rem;
              color: cornflowerblue;
            }
          }

          .mapTitle:first-child {
            margin-right: .2rem;
          }
        }

        .map {
          top: -.51rem;
          width: 96.4%;
          margin-left: 1.8%;
          position: relative;
          height: 6.6rem;

          .map4 {
            width: 200%;
            height: 110%;
            left: -45%;
          }
        }
      }

      .rightRight {
        float: right;
        width: 35%;

        .item {
          font-size: .16rem;
          padding-top: 10px;
          width: 100%;
          background: rgba(0, 0, 0, 0.3);
          text-align: center;
          position: relative;

          .itemTitle {
            z-index: 10;
            position: absolute;
            top: -0.25rem;
            left: 0;
            left: 0;
            height: .5rem;
            font-size: .18rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
              width: 2.3rem;
              height: 0.5rem;
              background: url("../assets/box.png") no-repeat;
              line-height: 0.5rem;
              background-size: 100% 100%;
            }
          }

          .btnClass {
            position: absolute;
            right: 2%;
            top: .5rem;
            display: flex;
            justify-content: flex-end;
            z-index: 9;

            .list {
              border: 1px solid #ffffff;
              color: #48D1CC;
              font-size: .16rem;
              padding: .04rem .12rem;
            }

            .list:first-child {
              border-radius: .03rem 0 0 .03rem;
            }

            .list:last-child {
              border-radius: 0 .03rem .03rem 0;
            }

            .active {
              color: #ffffff;
              background: #48D1CC;
              border: 1px solid #48D1CC;
            }
          }

          .tip {
            position: absolute;
            right: 2%;
            bottom: .05rem;
            display: flex;
            justify-content: flex-end;
            z-index: 9;
            font-size: .12rem;
            color: #ffffff;
          }

        }

        .company {
          margin-top: 7%;
          position: relative;
          width: 100%;
          height: 2.8rem;
          display: flex;
          justify-content: center;

          .itemCon {
            width: 70%;
            height: calc(100% - .4rem);
            //position: absolute;
            //right: 10%;
            //bottom: -39%;
            //-webkit-transform: translateY(-50%);
            //-ms-transform: translateY(-50%);
            //transform: translateY(-50%);
            font-size: .17rem;
            display: flex;
            //justify-content: center;
            align-content: center;
            flex-wrap: wrap;

            .itemContent {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: .08rem;
              position: relative;

              .radio {
                position: absolute;
                left: -.03rem;
                width: .12rem;
                height: .12rem;
                border-radius: 50%;
                background: black;
              }

              .radio1 {
                background: #f36f8a;
              }

              .radio2 {
                background: #26F3E8;
              }

              .radio3 {
                background: #97E001;
              }

              .radio4 {
                background: #B56046;
              }

              .radio5 {
                background: rgba(255, 198, 40, 1);
              }

              .radio6 {
                background: #ffffff;
              }

              .radio7 {
                background: #B37ddd;
              }

              .radio8 {
                background: #CA36D2;
              }

              &:first-child {
                margin-bottom: .1rem;
              }

              &:last-child {
                margin-bottom: 0;
              }

              span {
                color: #0089FF;
                width: 50%;
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
              }
            }
          }
        }

        #deviceChart {
          width: 100%;
          height: 3rem;
        }
      }

      .rightBottom {
        padding: 0 0 0 .2rem;

        .item {
          font-size: .16rem;
          //margin-bottom: .1rem;
          width: 100%;
          text-align: center;
          position: relative;

          .itemTitle {
            z-index: 10;
            position: absolute;
            top: -0.25rem;
            left: 0;
            height: .52rem;
            font-size: .18rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
              width: 2.3rem;
              height: 0.52rem;
              background: url("../assets/box.png") no-repeat;
              line-height: 0.52rem;
              background-size: 100% 100%;
            }
          }

          .itemRight {
            width: 35%;
            position: absolute;
            right: 5%;
            top: 60%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: .12rem;
            display: flex;
            flex-wrap: wrap;

            .itemContent {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: .08rem;
              position: relative;

              .radio {
                position: absolute;
                left: -.03rem;
                width: .08rem;
                height: .08rem;
                border-radius: 50%;
                background: black;
              }

              .radio1 {
                background: #f36f8a;
              }

              .radio2 {
                background: #26F3E8;
              }

              .radio3 {
                background: #97E001;
              }

              .radio4 {
                background: #B56046;
              }

              .radio5 {
                background: rgba(255, 198, 40, 1);
              }

              .radio6 {
                background: #ffffff;
              }

              .radio7 {
                background: #B37ddd;
              }

              .radio8 {
                background: #CA36D2;
              }

              &:first-child {
                margin-bottom: .1rem;
              }

              &:last-child {
                margin-bottom: 0;
              }

              span {
                color: #0089FF;
                width: 50%;
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
              }
            }
          }

          .itemBg {
            background: rgba(0, 0, 0, 0.3);
          }

          .btnClass {
            position: absolute;
            right: 2%;
            top: .5rem;
            display: flex;
            justify-content: flex-end;
            z-index: 9;

            .list {
              border: 1px solid #ffffff;
              color: #48D1CC;
              font-size: .16rem;
              padding: .04rem .12rem;
            }

            .list:first-child {
              border-radius: .03rem 0 0 .03rem;
            }

            .list:last-child {
              border-radius: 0 .03rem .03rem 0;
            }

            .active {
              color: #ffffff;
              background: #48D1CC;
              border: 1px solid #48D1CC;
            }
          }

          .tip {
            position: absolute;
            right: 2%;
            bottom: .05rem;
            display: flex;
            justify-content: flex-end;
            z-index: 9;
            font-size: .12rem;
            color: #ffffff;
          }


        }

        #thisMonth {
          width: 100%;
          height: 3.6rem;
        }
      }
    }

    .item:before, .boxFoot:before {
      border-left: 2px solid #02a6b5;
      left: 0;
    }

    .item:after, .boxFoot:after {
      border-right: 2px solid #02a6b5;
      right: 0;
    }

    .item:before, .item:after {
      position: absolute;
      width: .1rem;
      height: .1rem;
      content: '';
      top: 0;
      border-top: 2px solid #02a6b5;
      border-radius: 0.03rem;
    }

    .boxFoot:before, .boxFoot:after {
      position: absolute;
      width: .1rem;
      height: .1rem;
      content: '';
      bottom: 0;
      border-bottom: 2px solid #02a6b5;
      border-radius: 0.03rem;
    }
  }
}
</style>
