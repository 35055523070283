<template>
  <div class="index">
    <div id="load">
      <!-- 加载动画 -->
      <div class="load_img" v-if="showLoad">
        <img class="jzxz1" src="../../assets/jzxz1.png">
        <img class="jzxz2" src="../../assets/jzxz2.png">
      </div>
      <!-- 大屏内容 -->
      <div class="content" v-else>
        <IndexContent :info="info" :cut-data="cutData" :device-list="deviceList" :org-y="orgY" :org-list="orgList"
                      :rate-x="rateX" :rate-list="rateList" :cut-list="cutList" :map-list="dataMapList"
                      :slab-cut-area-end="slabCutAreaEnd" :cell-cut-area-end="cellCutAreaEnd"/>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api";

const api = new Api()
// import Utils from '../../utils'
//
// const util = new Utils()
import IndexContent from "@/components/indexContent";

export default {
  name: 'Index',
  components: {
    IndexContent
  },
  data() {
    return {
      slabCutAreaEnd: 0,
      cellCutAreaEnd: 0,
      multiple: 2,
      param: {
        beginTime: ''
        // beginTime: util.getLastMonth()
      },
      langMinuted: null,
      intervalMinuted: null,
      intervalHour: null,
      btnActive: 0,
      showLoad: true,
      timer: null,
      info: [
        {name: this.$t('lsmc.orgNum'), num: 0},
        {name: this.$t('lsmc.orgNew'), num: 0},
        {name: this.$t('lsmc.userNum'), num: 0},
        {name: this.$t('lsmc.userNew'), num: 0},
        {name: this.$t('lsmc.orgUserNum'), num: 0},
        {name: this.$t('lsmc.orgUserNew'), num: 0},
        {name: this.$t('lsmc.deviceNum'), num: 0},
        {name: this.$t('lsmc.deviceNew'), num: 0}
      ],
      cutData: {}, // 切割数据
      deviceList: [], // 设备切割面积-本地、云端
      orgY: [], // 企业切割Y轴
      orgList: [], // 企业切割数据
      rateX: [], // 切割率x轴
      rateList: [], // 切割率数据
      cutList: [], // 设备切割
      dataMapList: [], // 地图数据,
      reqNum: 0  //请求完成数
    }
  },
  mounted() {
    this.getBoardTotal()
    this.getBoardCutArea()
    this.getBoardGrowthRate()
    this.getBoardDevice()
    this.getBoardMap()
  },
  beforeDestroy() {
    this.reqNum = 0
    this.clear()
    clearInterval(this.timer)
    this.timer = null
  },
  created() {
    this.dataHourRefresh();
    this.dataMinuteRefresh();
    // this.langMinuteRefresh()
  },
  methods: {
    // 停止定时器
    clear() {
      clearInterval(this.intervalMinuted); //清除计时器
      clearInterval(this.intervalHour); //清除计时器
      clearInterval(this.langMinuted)
      this.langMinuted = null
      this.intervalMinuted = null; //设置为null
      this.intervalHour = null; //设置为null
    },
    // langMinuteRefresh() {
    //   // TODO 5秒钟定时器
    //   // 计时器正在进行中，退出函数
    //   if (this.langMinuted != null) {
    //     return;
    //   }
    //   // 计时器为空，操作
    //   this.langMinuted = setInterval(() => {
    //     if (this.$i18n.locale == 'zh') {
    //       this.$i18n.locale = 'en'
    //     } else {
    //       this.$i18n.locale = 'zh'
    //     }
    //     this.getBoardTotal()
    //     this.getBoardCutArea()
    //     this.getBoardGrowthRate()
    //     this.getBoardDevice()
    //   }, 5000);
    // },
    dataMinuteRefresh() {
      // TODO 十秒钟定时器
      // 计时器正在进行中，退出函数
      if (this.intervalMinuted != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalMinuted = setInterval(() => {
        //加载数据函数
        this.getBoardDevice()
        this.getBoardCutArea()
      }, 10000);
    },
    dataHourRefresh() {
      // TODO 一小时定时器
      // 计时器正在进行中，退出函数
      if (this.intervalHour != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalHour = setInterval(() => {
        // 所需一小时刷新一次的数据
        this.getBoardTotal();
        this.getBoardMap()
        this.getBoardGrowthRate()
      }, 36000000);
    },
    goInside() {
      this.reqNum++
      if (this.reqNum >= 5) {
        this.$forceUpdate()
        // this.showLoad = false
        this.timer = setTimeout(() => {
          this.showLoad = false
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.$router.push('/lsdc')
            clearTimeout(this.timer)
          },15000)
        }, 0)
      }
    },
    goErrorInside() {
      this.reqNum = 0
      location.reload()
      // this.reqNum++
      // if (this.reqNum >= 5) {
      //   this.$forceUpdate()
      //   this.timer = setTimeout(() => {
      //     clearTimeout(this.timer)
      //     this.timer = setTimeout(() => {
      //       this.$router.push('/lsdc')
      //       clearTimeout(this.timer)
      //     },1100)
      //   }, 500)
      // }
    },
    // 大屏数据
    getBoardTotal() {
      api.getBoardTotal(this.param).then(res => {
        this.info[0].name = this.$t('lsmc.orgNum')
        this.info[0].num = res.data.totalOrgNum * this.multiple + 1
        this.info[1].name = this.$t('lsmc.orgNew')
        this.info[1].num = res.data.increaseOrgNum * this.multiple + 2
        this.info[2].name = this.$t('lsmc.userNum')
        this.info[2].num = res.data.totalUserNum * this.multiple + 2
        this.info[3].name = this.$t('lsmc.userNew')
        this.info[3].num = res.data.increaseUserNum * this.multiple + 1
        this.info[4].name = this.$t('lsmc.orgUserNum')
        this.info[4].num = res.data.totalUserOrgNum * this.multiple
        this.info[5].name = this.$t('lsmc.orgUserNew')
        this.info[5].num = res.data.increaseUserOrgNum * this.multiple + 3
        this.info[6].name = this.$t('lsmc.deviceNum')
        this.info[6].num = res.data.totalDeviceNum * this.multiple
        this.info[7].name = this.$t('lsmc.deviceNew')
        this.info[7].num = res.data.increaseDeviceNum * this.multiple
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    getBoardCutArea() {
      this.deviceList = []
      api.getBoardCutArea(this.param).then(res => {
        this.cutData = res.data
        this.slabCutAreaEnd = this.cutData.slabCutArea * this.multiple
        this.cellCutAreaEnd = this.cutData.cellCutArea * this.multiple
        this.deviceList = [
          {name: this.$t('lsmc.cloud'), value: res.data.cloudCutArea * this.multiple},
          {name: this.$t('lsmc.local'), value: res.data.localCutArea * this.multiple}
        ]
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    getBoardGrowthRate() {
      this.param.rateType = this.btnActive + 1
      this.rateList = []
      this.rateX = []
      api.getBoardGrowthRate(this.param).then(res => {
        res.data.map(item => {
          this.rateList.push({
            value: item.cutArea * this.multiple,
            rate: item.growthRate
          })
          this.rateX.push(item.day)
        })
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    getBoardDevice() {
      api.getBoardDevice(this.param).then(res => {
        res.data.map(item => {
          item.deviceCutArea = item.deviceCutArea * this.multiple
        })
        res.data.unshift({
          deviceModelCode: this.$t('lsmc.device'),
          deviceCutArea: this.$t('lsmc.cutNum') + '(m²)'
        })
        this.cutList = res.data
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    // getBoardOrg() {
    //   this.orgList = []
    //   this.orgY = []
    //   api.getBoardOrg(this.param).then(res => {
    //     res.data.map(item => {
    //       this.orgList.unshift({
    //         value: item.orgCutArea * this.multiple,
    //         name: item.orgName
    //       })
    //       this.orgY.unshift(item.orgName)
    //     })
    //     // this.drawCompany()
    //   })
    // },
    getBoardMap() {
      this.dataMapList = []
      api.getBoardMap(this.param).then(res => {
        res.data.map(item => {
          const re = /省|市/g;  //全局匹配
          this.dataMapList.push({
            name: item.province.replace(re, ''),
            selected: false,
            orgNum: item.orgNum * this.multiple,
            deviceNum: item.deviceNum * this.multiple
          })
        })
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
  }
}
</script>

<style scoped>
/*样式*/
.content {
  width: 100%;
  height: 100%;
}

/* 加载旋转动画 */
#load {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(../../assets/lsdcBg.png) no-repeat #061537;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 999
}

.load_img {
  position: absolute;
  left: calc(50% - 182px);
  top: calc(50% - 182px);
}

.load_img img {
  position: absolute;
  left: 0;
  top: 0;
}

.load_img .jzxz1 {
  animation: xz1 8s infinite linear;
}

@keyframes xz1 {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.load_img .jzxz2 {
  animation: xz2 7s infinite linear;
}

@keyframes xz2 {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>

