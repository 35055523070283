import request from '@/utils/request'
const baseUrl = process.env.VUE_APP_BASE_SERVER
class Api {
    // TODO 看板数据总览
    getBoardTotal(data) {
        return request({
            url: baseUrl + "/reportBoard/getBoardTotal",
            method: "POST",
            data
        })
    }
    // TODO 切割面积数据
    getBoardCutArea(data) {
        return request({
            url: baseUrl + "/reportBoard/getBoardCutArea",
            method: "POST",
            data
        })
    }
    // TODO 切割增长率
    getBoardGrowthRate(data) {
        return request({
            url: baseUrl + "/reportBoard/getBoardGrowthRate",
            method: "POST",
            data
        })
    }
    // TODO 设备切割量
    getBoardDevice(data) {
        return request({
            url: baseUrl + "/reportBoard/getBoardDevice",
            method: "POST",
            data
        })
    }
    // TODO 组织切割量
    getBoardOrg(data) {
        return request({
            url: baseUrl + "/reportBoard/getBoardOrg",
            method: "POST",
            data
        })
    }
    // TODO 地图
    getBoardMap(data) {
        return request({
            url: baseUrl + "/reportBoard/getBoardMap",
            method: "POST",
            data
        })
    }
}

export default Api
